import "../styles/Partenaires.css";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import Logo2KD from "../assets/icones/NAV XL.png";
import LogoAICargo from "../assets/icones/AI CARGO.png";
import LogoFNTR from "../assets/icones/FNTR.png";
import LogoFranceMob from "../assets/icones/FRANCE MOBILITY.png";
import LogoGTP31 from "../assets/icones/GTP31.png";
import LogoOTRE from "../assets/icones/OTRE BRETAGNE.png";
import LogoSAFAR from "../assets/icones/SAFAR.png";
import LogoANC from "../assets/icones/ANC SYSTEM.png";
import LogoVEHIZEN from "../assets/icones/VEHIZEN.png";
import LogoSTR from "../assets/icones/STR47.png";
import LogoEngie from "../assets/icones/ENGIE.png";
import LogoGlady from "../assets/icones/GLADY.png";

import { Container } from "reactstrap";
import { useState } from "react";
import { Box, Button } from "@mui/material";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    img: LogoFranceMob,
  },
  {
    img: LogoEngie,
  },
  {
    img: LogoAICargo,
  },
  {
    img: Logo2KD,
  },
  {
    img: LogoVEHIZEN,
  },
  {
    img: LogoGlady,
  },
  {
    img: LogoFNTR,
  },
  {
    img: LogoOTRE,
  },
  {
    img: LogoANC,
  },
  {
    img: LogoSTR,
  },
  {
    img: LogoGTP31,
  },
  {
    img: LogoSAFAR,
  },
];

function Partenaires() {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return (
    <Container className="Partenaires">
      <div className="content">
        <div className="left-content">
          <div className="title color-grey">Nos partenaires</div>
          <div className="subtitle color-blue">rejoingnez-nous !</div>
        </div>
        <div className="right-content">
          <Button
            className="nav-button"
            disabled={activeStep === 0}
            onClick={handleBack}>
            <ChevronLeftIcon />
          </Button>
          <AutoPlaySwipeableViews
            className="swipe-view-container"
            axis={"x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents>
            {images.map((step, index) => (
              <div key={"div-" + index}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    component="img"
                    className="box-img"
                    src={step.img}
                    alt={"box-" + index}
                  />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <Button
            className="nav-button"
            disabled={activeStep === maxSteps - 1}
            onClick={handleNext}>
            <ChevronRightIcon />
          </Button>
        </div>
        {/*        
        <div sm="7" xs="0">
          <div style={{ alignItems: "flex-end" }}>
            <div className="logo-item" sm="4" xs="6">
              <a
                rel="noreferrer"
                href="https://www.aicargofoundation.org"
                target="_blank"
              >
                <img
                  className="logo-img"
                  alt="logo-aiCargo"
                  src={LogoAICargo}
                />
                <p>aicargofoundation.org</p>
              </a>
            </div>
            <div className="logo-item" sm="4" xs="6">
              <a rel="noreferrer" href="https://www.2kd.fr" target="_blank">
                <img className="logo-img" alt="logo-2kd" src={Logo2KD} />
                <p>2kd.fr</p>
              </a>
            </div>
            <div className="logo-item" sm="4" xs="6">
              <a
                rel="noreferrer"
                href="https://www.groupesafar.com"
                target="_blank"
              >
                <img className="logo-img" alt="logo-safar" src={LogoSAFAR} />
                <p>groupesafar.com</p>
              </a>
            </div>
          </div>
          <div style={{ alignItems: "flex-end" }}>
            <div className="logo-item" sm="4" xs="6">
              <a rel="noreferrer" href="https://www.fntr.fr" target="_blank">
                <img className="logo-img" alt="logo-fntr" src={LogoFNTR} />
                <p>fntr.fr</p>
              </a>
            </div>
            <div className="logo-item" sm="4" xs="6">
              <a rel="noreferrer" href="https://www.otre.bzh" target="_blank">
                <img className="logo-img" alt="logo-otre" src={LogoOTRE} />
                <p>otre.bzh</p>
              </a>
            </div>
            <div className="logo-item" sm="4" xs="6">
              <a rel="noreferrer" href="https://www.gtp31.com" target="_blank">
                <img className="logo-img" alt="logo-gtp31" src={LogoGTP31} />
                <p>gtp31.com</p>
              </a>
            </div>
          </div>
          <div style={{ alignItems: "flex-end" }}>
            <div className="logo-item" sm="4" xs="6">
              <a
                rel="noreferrer"
                href="https://www.esystems.fr"
                target="_blank"
              >
                <img className="logo-img" alt="logo-anc" src={LogoANC} />
                <p>esystems.fr</p>
              </a>
            </div>
            <div className="logo-item" sm="4" xs="6">
              <a
                rel="noreferrer"
                href="https://www.vehizen.com/"
                target="_blank"
              >
                <img
                  className="logo-img"
                  alt="logo-vihizen"
                  src={LogoVEHIZEN}
                />
                <p>vehizen.com</p>
              </a>
            </div>
          </div>
          <div className="fance-mobilite-item">
            <div sm="3" xs="0">
              <a
                rel="noreferrer"
                href="https://www.francemobilites.fr"
                target="_blank"
              >
                <img
                  className="france-mob-img"
                  alt="logo-franceMobilite"
                  src={LogoFranceMob}
                />
              </a>
            </div>
            <div sm="9" xs="0" className="france-mob-frame">
              <div>
                <p>
                  Membre de la communauté France Mobilités du Ministère de la
                  Transition Écologique
                </p>
                <a
                  rel="noreferrer"
                  href="https://www.francemobilites.fr"
                  target="_blank"
                >
                  francemobilites.fr
                </a>
                <a
                  rel="noreferrer"
                  href="https://www.ecologie.gouv.fr"
                  target="_blank"
                >
                  ecologie.gouv.fr
                </a>
              </div>
              <div className="label-bonne-route">Bonne Route !</div>
            </div>
          </div>
        </div>
        <div className="earth-item" sm="3" xs="0">
          <div>
            <img
              className="earth-img"
              alt="leader-ico"
              src={EarthPartenaires}
            />
          </div>
        </div>
        */}
      </div>
    </Container>
  );
}

export default Partenaires;
